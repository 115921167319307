import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { MyHttpApi, PrivilegeItem, StorageUnitUpdateRequest } from 'utils/api';
import { getSelectedCorporation, privilegeItemFromElement, privilegeToTypeAndName } from 'utils/corporation-util';

@autoinject
export class PosStorageUnitEdit {
  private storageUnit: StorageUnitUpdateRequest = {
    name: "",
    baseUnit: "PCS",
    value: 0.001,
    type: "CLIENT",
    delete: false,
  };
  private privilege?: PrivilegeItem;
  private isMaster = false;
  private typeAndName = "";
  private disabled = false;

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
  }

  async activate(params: {id?: string, isMaster?: string}) {
    this.isMaster = params.isMaster === "true";
    if (params.id) {
      let storageUnit = await this.api.storageUnitById({ id: params.id });
      this.storageUnit = { ...storageUnit, delete: !!storageUnit.deleteTime, type: 'CLIENT' };
      this.privilege = privilegeItemFromElement(storageUnit);
    }
    if (!this.isMaster && !params.id) {
      this.privilege = getSelectedCorporation();
    } else if (this.isMaster) {
      this.privilege = undefined;
    }

    if (this.privilege) {
      let canEdit = await this.api.privilegeCanEdit(this.privilege);
      if (this.privilege.id && this.privilege.type === 'CLIENT') {
        const bgr = await this.api.businessGroupRestrictionsByClientId({ clientId: this.privilege.id });
        canEdit = bgr.clientSpecificStorageUnits;
      }
      this.disabled = !canEdit;
    }

    this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);
  }

  @computedFrom("disabled")
  get disabledText() {
    if (this.disabled) {
      return this.i18n.tr('businessGroupRestriction.readOnly');
    }
    return "";
  }

  async save(deleted: boolean) {
    await this.api.storageUnitUpdate({
      ...this.storageUnit,
      privilegeId: this.privilege?.id,
      type: this.privilege?.type || 'CLIENT',
      delete: deleted,
    });
    this.router.navigateBack();
  }
}
